import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import Code from '../../components/code';
import Video from '../../components/video';
import SiteVideo from '../../videos/create_site.mp4';
import Section from '../../components/section';



const Row = styled.div`
  display: flex;
  flex-direction: row;
`;




const Col = styled.div`
  display: flex;
  flex-direction: column;
`;


const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;


class CodeSection extends Component {

  state = {
    tabSelected: 'Platform',
    codeSiteAnimState: 'paused',
    codeModelAnimState: 'paused'
  }

  render() {
    return (
      <Section
        style={{marginTop: 128}}
        onVisible={() => {
          setTimeout(() => {
            this.setState({
              codeSiteAnimState: 'running'
            })
          }, 1500);

        }}
        >
        <Row style={{justifyContent: 'center'}}>
          <h1 style={{textAlign: 'center', flex: 1}}>
            Develop fully customizable models.<br/> <span style={{color: '#C9DDFF'}}>No compromises.</span>
          </h1>
        </Row>
        <Row style={{justifyContent: 'center'}}>
          <p style={{fontSize: 18, textAlign: 'center'}}>
            Get started quickly with state of the art open-source algorithms. <br/>Take full control with our open-source Python SDK.
            {/*You own the code, it will work with or without our platform.*/}
          </p>
        </Row>
        <InnerContainer style={{marginTop: 16}}>
          <Col style={{flex: 4, marginRight: 32, marginTop: 32, maxWidth: 650}}>
            <Video
              ref={r => this.videoRef = r}
              style={{width: '100%', pointerEvents: 'none', borderRadius: 4, minWidth: 500, boxShadow: '0 2px 8px #121212'}}
              videoSource={SiteVideo}
              title=''
            />
          </Col>

          <Col style={{flex: 3, overflow: 'hidden'}}>
            <Code
              title='CREATE SITE'
              animState={this.state.codeSiteAnimState}
              codeRows={[
                {code: 'import rebase as rb'},
                {code: '', extraDuration: .5},
                {code: 'my_site = rb.Site(', extraDuration: 0.1},
                {code: '\tname="My site",', extraDuration: 0},
                {code: '\ttype="wind",', extraDuration: 0.1},
                {code: '\tlatitude=57.3,', extraDuration: 0},
                {code: '\tlongitude=7.1,', extraDuration: 0},
                {code: '\tcapacity=2000', extraDuration: 0.2},
                {code: ')', extraDuration: 0.8},
                {code: 'rb.create(my_site)', onFinished: () => {
                  setTimeout(() => {
                    if (this.videoRef){
                      this.videoRef.play();
                      setTimeout(() => {
                        if (this.videoRef){
                          this.videoRef.pause();
                          this.videoRef.seekTime(5.5);
                          this.setState({codeModelAnimState: 'running'})
                        }
                      }, 3000);
                    }
                  }, 1000)
                }},
              ]}
              outRows={[
                {out: 'Creating site...', onFinished: () => {}}
              ]}
            />
            <Code
              title='TRAIN MODEL'
              style={{marginTop: 16}}
              animState={this.state.codeModelAnimState}
              codeRows={[
                {code: 'import lightgbm as lgb',},
                {code: '', extraDuration: .1},
                {code: 'class CustomWindModel(rb.Model):',},
                {code: '', extraDuration: .2},
                {code: '\tdef predict(self):',},
                {code: '\t... your code'},
                {code: '', extraDuration: .2},
                {code: '\tdef train(self):'},
                {code: '\t... your code'},
                {code: '', extraDuration: .9},
                {code: 'rb.train(CustomWindModel)'},
              ]}
            outRows={[
              {out: 'Training model...', onFinished: () => this.videoRef && this.videoRef.play()}
            ]}
            />
          </Col>
        </InnerContainer>
      </Section>
    );
  }

}

export default CodeSection;
