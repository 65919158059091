import React from "react"

/*const Video = ({ videoSrcURL, videoTitle, style}) => (
    <iframe
      style={{...style, background: 'red'}}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      allowfullscreen
    />
)*/


class Video extends React.Component {

  pause = () => {
    if (this.ref){
      this.ref.pause();
    }
  }


  play = () => {
    if (this.ref){
      this.ref.play();
    }
  }

  seekTime = (seconds) => {
    if (this.ref){
      this.ref.currentTime = seconds;
    }
  }

  render(){
    const {videoSource, videoTitle, style} = this.props;
    return (
      <video
        ref={r => this.ref = r}
        style={{...style}} //autoplay="autoplay" playsinline muted loop
        muted="muted"
        >
        <source src={videoSource} type="video/mp4"/>
      </video>
    )
  }
}


export default Video
